<template lang="pug">
v-container(grid-list-md)
  h1.text-center.section-title.mb-5 Выберите регион
  svg-map
</template>

<script>
import SvgMap from '@/components/SvgMap';

export default {
  components: { SvgMap },
};
</script>
